<template>
	<div>
	<div v-show="!showRecord">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;"
							@change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;"
							@change="getCardPolicyList">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				<a-row v-show="showAll">
					<a-form-item label="会员昵称" name="memberName" class="ui-form__item">
						<a-input v-model:value="formState.memberName" placeholder="请输入会员昵称"></a-input>
					</a-form-item>

					<a-form-item label="会员手机" name="memberPhone" class="ui-form__item">
						<a-input v-model:value="formState.memberPhone" placeholder="请输入会员手机"></a-input>
					</a-form-item>

					<a-form-item label="线上会员卡号" name="cardNumber" class="ui-form__item">
						<a-input v-model:value="formState.cardNumber" placeholder="请输入线上会员卡号"></a-input>
					</a-form-item>

					<a-form-item label="线上会员卡等级" name="cardId" class="ui-form__item">
						<a-select v-model:value="formState.cardId" style="width: 180px;" placeholder="请选择线上会员卡等级">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cardAllList" :key="item.id" :value="item.id">
								{{ item.title }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['coupon_stored_info_export']">
							<exportComponent type="baseRechargeCard" :searchData="searchData"></exportComponent>
						</span>

						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 1800 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'userInfo'">
							<div>会员昵称：{{ record.memberName || '-' }}</div>
							<div>会员手机：{{ record.memberPhone || '-' }}</div>
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-else-if="column.key === 'createTime'">
							<div>{{ transDateTime(record.createTime) }}</div>
						</template>
						<template v-else-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['coupon_stored_info_disabled']"
											v-if="record.isDisabled === 0" @click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['coupon_stored_info_open']" v-else
											@click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['coupon_stored_info_modify']"
											@click="onModifyBalance(record)">
											<a-menu-item>
												充值金额
											</a-menu-item>
										</div>
										<div v-permission="['coupon_stored_info_record']" @click="onRecord(record)">
											<a-menu-item>充值记录</a-menu-item>
										</div>
										<div v-permission="['coupon_stored_info_unbind']" @click="onUnbind(record)">
											<a-menu-item>解绑</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>

			<a-modal title="修改线上会员卡余额" width="600px" v-model:visible="showModal" @cancel="onModifyCancel"
				@ok="onModifyOk">
				<a-spin :spinning="loading">
					<a-form ref="modifyForm" name="modifyForm" :model="modelRef" :labelCol="{ span: 6 }"
						:wrapperCol="{ span: 14 }">
						<a-form-item label="现有余额">
							{{ modifyItem.balance || 0 }} 元
						</a-form-item>

						<a-form-item label="充值金额" name="balance" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input-number v-model:value="modelRef.balance" :min="'-' + modifyItem.balance" :precision="2" placeholder="请输入">
							</a-input-number> 元
						</a-form-item>
						
						<a-form-item label="充值后金额">
							{{ (modifyItem.balance + (modelRef.balance || 0)).toFixed(2) }} 元
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
		<Recharge v-if="showRecord" :id="id" @back="onBack"></Recharge>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import exportComponent from '@/components/exportReport/exportReport.vue';
	import Recharge from './recharge.vue';
	import {
		getOrganizationList,
		getCinemaList
	} from '@/service/modules/cinema.js';
	import {
		getCardPolicyList,
		getCardInfoList,
		pullOnShelvesCardInfo,
		pullOffShelvesCardInfo,
		modifyBalance,
		cardUnbinding
	} from "@/service/modules/coupon.js";
	export default {
		components: {
			Icon,
			exportComponent,
			Recharge
		},
		data() {
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				cardAllList: [],
				formState: {
					type: 3,
					//organizationId: 0,
					cinemaId: 0,
					cardId: 0,
					status: 0,
				},
				searchData: {},
				showRecord: false,
				id: 0,
				showModal: false,
				modifyItem: {},
				modelRef: {},
				selectedRowKeys: [],
				selectedListData: [],

				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '卡等级',
					dataIndex: 'cardTitle'
				}, {
					title: '卡号',
					dataIndex: 'cardNumber'
				}, {
					title: '卡余额',
					dataIndex: 'balance',
					width: 120
				}, {
					title: '会员信息',
					key: 'userInfo'
				}, {
					title: '状态',
					width: 100,
					key: 'isDisabled'
				}, {
					title: '绑定时间',
					key: "createTime",
					width: 200
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 160
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getOrganizationList();
			//this.onSearch();
		},
		methods: {
			onBack() {
				this.showModal = false;
				this.showRecord = false;
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.selectedListData = [];
				this.selectedRowKeys = [];
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.searchData.isNeverExpire === -1) {
					this.searchData.isNeverExpire = undefined;
					this.time = [];
				}
				if (this.searchData.isNeverExpire === 1) {
					this.time = [];
				}
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId :
					undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.status = this.searchData.status ? this.searchData.status : undefined;
				this.searchData.cardId = this.searchData.cardId ? this.searchData.cardId : undefined;
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].startOf('day').valueOf() / 1000;
					this.searchData.endTime = this.time[1].endOf('day').valueOf() / 1000;
				}
				this.getData();
			},
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCardInfoList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						type: 3,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				this.cardAllList = [];
				this.formState.cardId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					// if (ret.data.list && ret.data.list.length) {
					// 	this.formState.cinemaId = ret.data.list[0].id;
					// 	this.getCardPolicyList(this.formState.cinemaId);
					// }
					this.cinemaAllList = ret.data.list;
				}
			},
			async getCardPolicyList(cinemaId) {
				this.cardAllList = [];
				this.formState.cardId = 0;
				if (!cinemaId) return;
				let ret = await getCardPolicyList({
					page: 1,
					pageSize: 999999,
					type: 3,
					organizationId: this.formState.organizationId ? this.formState.organizationId : undefined,
					cinemaId: cinemaId ? cinemaId : undefined
				})
				if (ret.code === 200) {
					this.cardAllList = ret.data.list;
				}
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: "确定" + (item.isDisabled === 0 ? '禁用' : '启用') + '此卡吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret;
							if (item.isDisabled === 0) {
								ret = await pullOffShelvesCardInfo({
									id: item.id
								})
							} else {
								ret = await pullOnShelvesCardInfo({
									id: item.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			onUnbind(record) {
				this.$confirm({
					title: '提示',
					content: '确定执行解绑操作吗？',
					onOk: async () => {
						this.loading = true;
						let ret = await cardUnbinding({
							userCardId: record.id
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('解绑成功！');
							this.getData();
						}
					}
				})
			},
			onModifyBalance(record) {
				this.modifyItem = record;
				this.modelRef = {};
				this.showModal = true;
			},
			onModifyCancel() {
				this.$refs.modifyForm.resetFields();
				this.modifyItem = {};
				this.showModal = false;
			},
			onModifyOk() {
				this.$refs.modifyForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret = await modifyBalance({
							id: this.modifyItem.id,
							balance: this.modelRef.balance
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('余额调整成功');
							this.$refs.modifyForm.resetFields();
							this.showModal = false;
							this.getData();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			onRecord(record) {
				this.id = record.id;
				this.showRecord = true;
			},
			onSelectChange(record, selected) {
				if (selected) {
					if (this.selectedRowKeys.length < 500) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.$message.warn('最大可选择数据不允许大于500条');
					}
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					let showWain = true;
					this.list.forEach(item => {
						if (this.selectedRowKeys.length < 500) {
							if (this.selectedRowKeys.indexOf(item.id) === -1 && item.status != 7 && item
								.isNeverExpire != 1 && item.usableTotal) {
								this.selectedListData.push(JSON.parse(JSON.stringify(item)));
								this.selectedRowKeys.push(item.id);
							}
						} else {
							if (showWain) {
								showWain = false;
								this.$message.warn('最大可选择数据不允许大于500条');
							}
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			getCheckboxProps(record) {
				return {
					disabled: (record.status === 7 || record.isNeverExpire === 1 || !record.usableTotal),
					id: record.id.toString()
				}
			},
			disabledDate(current) {
				return current && current < this.moment().endOf('day');
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
